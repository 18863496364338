import { ColdStartDate } from '@api/__gen__/gql'
import { AddCircleOutline } from '@mui/icons-material'
import {
  DataGridPremium,
  GridColDef,
  GridRowsProp,
} from '@mui/x-data-grid-premium'
import Button from 'app/components/Button/Button'
import TableHeader from 'app/components/Tables/TableHeader'
import Tag from 'app/components/Tag/Tag'
import { formatDate } from 'app/helpers/date'
import { ModalState } from 'app/packages/internal/customerConfigs/customerConfigs.types'
import React, { useState } from 'react'
import styled from 'styled-components'
import ColdStartDatesAddEditModal from './ColdStartDatesAddEditModal'

const StyledEmptyState = styled.div`
  margin: 20px;
`

const StyledTableContainer = styled.div<{ $setupDetailsExpanded: boolean }>`
  max-height: ${({ $setupDetailsExpanded }) =>
    $setupDetailsExpanded ? 'calc(100vh - 535px)' : 'calc(100vh - 310px)'};
  height: ${({ $setupDetailsExpanded }) =>
    $setupDetailsExpanded ? 'calc(100vh - 535px)' : 'calc(100vh - 310px)'};

  & .MuiDataGrid-row:hover {
    cursor: pointer;
  }

  & .MuiDataGrid-cell:focus {
    outline: none;
  }
  & .MuiDataGrid-columnHeader:focus {
    outline: none;
  }
  & .MuiDataGrid-columnHeader:focus-within {
    outline: none;
  }

  & .MuiDataGrid-withBorderColor {
    border-top: none;
    border-radius: 0;
  }
`

const StyledTableHeaderButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`

const StyledAddNewButton = styled(Button)`
  padding: 6px 16px;
`

const StyledAddNewIcon = styled(AddCircleOutline)`
  font-size: 20px;
`

export type ColdStartDateRow = Pick<
  ColdStartDate,
  'storeId' | 'workflowId' | 'firstOrderDate' | 'isColdStartComplete'
>

export interface Props {
  isLoading?: boolean
  rows: ColdStartDateRow[]
  setupDetailsExpanded: boolean
  onRefetch?: () => Promise<void>
}

const ColdStartDateColumns: GridColDef[] = [
  {
    field: 'storeId',
    headerName: 'Store ID',
    width: 120,
    sortable: false,
  },
  {
    field: 'workflowId',
    headerName: 'Workflow ID',
    width: 120,
    sortable: false,
  },
  {
    field: 'firstOrderDate',
    headerName: 'First Order Date',
    description: 'This is the date that the coldstart will run on.',
    width: 150,
    sortable: true,
    renderCell: (params) => formatDate(params.value),
  },
  {
    field: 'isColdStartComplete',
    headerName: 'Is Complete?',
    description: `'No' means that this cold start is scheduled, 'Yes' means it has run.`,
    width: 130,
    sortable: false,
    filterable: true,
    renderCell: (params) =>
      params.value ? (
        <Tag text="Yes" color="highlight_08" />
      ) : (
        <Tag text="No" color="highlight_02" />
      ),
  },
]

const initialState = {
  filter: {
    filterModel: {
      items: [
        { field: 'isColdStartComplete', operator: 'equals', value: 'false' },
      ],
    },
  },
}

export default function ColdStartDatesTable({
  isLoading,
  rows,
  setupDetailsExpanded,
  onRefetch,
}: Props) {
  const [modalState, setModalState] = useState<ModalState | null>(null)
  const [activeRow, setActiveRow] = useState<ColdStartDateRow | null>(null)

  const handleRowClick = (row: ColdStartDateRow) => {
    setActiveRow(row)
    setModalState(ModalState.edit)
  }

  const handleClose = () => {
    setModalState(null)
    setActiveRow(null)
  }

  const handleSuccess = async () => {
    if (onRefetch) {
      await onRefetch()
    }
    handleClose()
  }

  return (
    <>
      <StyledTableContainer $setupDetailsExpanded={setupDetailsExpanded}>
        <TableHeader>
          <StyledTableHeaderButtonsContainer>
            <StyledAddNewButton
              onClick={() => setModalState(ModalState.add)}
              startIcon={<StyledAddNewIcon />}>
              Add New
            </StyledAddNewButton>
          </StyledTableHeaderButtonsContainer>
        </TableHeader>
        <DataGridPremium
          rows={rows as GridRowsProp}
          getRowId={(row) => `${row.storeId}-${row.workflowId}`}
          slots={{
            noRowsOverlay: () => (
              <StyledEmptyState>
                No config found for this customer
              </StyledEmptyState>
            ),
          }}
          disableAggregation
          disableRowGrouping
          disableRowSelectionOnClick
          disableColumnSelector
          columns={ColdStartDateColumns}
          initialState={initialState}
          loading={isLoading}
          onRowClick={({ row }) => handleRowClick(row)}
        />
      </StyledTableContainer>

      {modalState && (
        <ColdStartDatesAddEditModal
          modalState={modalState}
          activeRow={activeRow ?? undefined}
          onClose={handleClose}
          onSuccess={handleSuccess}
          onDelete={
            modalState === ModalState.edit
              ? () => setModalState(ModalState.delete)
              : undefined
          }
        />
      )}
    </>
  )
}
