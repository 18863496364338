import { ScheduleColdStartsInput } from '@api/__gen__/gql'
import { gql, useMutation } from '@apollo/client'
import { useCustomers } from 'app/CustomerContext'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

export const SCHEDULE_COLD_STARTS = gql`
  mutation scheduleColdStarts($input: ScheduleColdStartsInput!) {
    scheduleColdStarts(input: $input) {
      succeeded {
        storeId
        workflowId
      }
      failed {
        code
        message
        coldStartIds {
          storeId
          workflowId
        }
      }
    }
  }
`

type UserScheduleColdStartsInput = Omit<ScheduleColdStartsInput, 'customerKey'>

export function useScheduleColdStartDate() {
  const { activeCustomerKey } = useCustomers()

  const [scheduleColdStarts, result] = useMutation(SCHEDULE_COLD_STARTS, {
    refetchQueries: ['CustomerConfigColdStartDates'],
  })

  return tuple([
    useCallback(
      async (input: UserScheduleColdStartsInput) => {
        const { errors } = await scheduleColdStarts({
          variables: {
            input: {
              ...input,
              customerKey: activeCustomerKey,
            },
          },
        })

        if (errors?.length) {
          throw new Error(
            `Schedule cold start dates failed: ${errors[0].message}`,
          )
        }
      },
      [activeCustomerKey, scheduleColdStarts],
    ),
    result,
  ])
}
