import { DialogContent, TextField, Tooltip } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import AlertMessageBlock from 'app/components/AlertMessageBlock/AlertMessageBlock'
import Button from 'app/components/Button/Button'
import Dialog, {
  Body,
  Footer,
  FooterActions,
  Header,
  Title,
} from 'app/components/Dialog/Dialog'
import Delete from 'app/components/Icons/Delete'
import useDraftState from 'app/hooks/useDraftState'
import { ModalState } from 'app/packages/internal/customerConfigs/customerConfigs.types'
import { toRem } from 'app/styles'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useCancelColdStartDate } from '../api/mutations/useCancelColdStartDate'
import { useScheduleColdStartDate } from '../api/mutations/useScheduleColdStartDate'
import { ColdStartDateRow } from './ColdStartDatesTable'

const FIELD_WIDTH = 304

const StyledDialogContent = styled(DialogContent)`
  overflow: hidden;
  padding: 0;
`

const SelectFieldContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: ${toRem(16)};
  width: ${toRem(FIELD_WIDTH)};
  gap: ${toRem(16)};
`

const StyledTextField = styled(TextField)`
  width: ${toRem(FIELD_WIDTH)};
  margin: 0;
`

export const StyledErrorMessage = styled(AlertMessageBlock)`
  margin-top: ${toRem(16)};
`

interface FormValues {
  storeId: string
  workflowId: string
  firstOrderDate: string
}

export interface Props {
  modalState: ModalState
  activeRow?: ColdStartDateRow
  onClose: () => void
  onSuccess: () => void
  onDelete?: () => void
}

function getInitialFormState(activeRow?: ColdStartDateRow): FormValues {
  if (!activeRow) {
    return {
      storeId: '',
      workflowId: '',
      firstOrderDate: '',
    }
  }

  return {
    storeId: activeRow.storeId,
    workflowId: activeRow.workflowId,
    firstOrderDate: activeRow.firstOrderDate,
  }
}

function validateFormValues(formValues: FormValues) {
  return !!(
    formValues.storeId &&
    formValues.workflowId &&
    formValues.firstOrderDate
  )
}

const today = dayjs().add(2, 'days').utc().startOf('day').toISOString()

const validateNumericInput = (value: string): string => {
  return value.replace(/[^0-9,\s]/g, '')
}

const STORE_TOOLTIP = 'You may add a comma-separated list of store IDs'
const WORKFLOW_TOOLTIP = 'You may add a comma-separated list of workflow IDs'
const DISABLED_TOOLTOP = 'Schedule a New Cold Start Date to change these values'

export default function ColdStartDatesAddEditModal({
  modalState,
  activeRow,
  onClose,
  onSuccess,
  onDelete,
}: Props) {
  const [scheduleColdStartDate] = useScheduleColdStartDate()
  const [cancelColdStartDate] = useCancelColdStartDate()
  const [formValues, setFormValues] = useDraftState(
    getInitialFormState(activeRow),
  )
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [datePickerTouched, setDatePickerTouched] = useState(false)
  const [formValid, setFormValid] = useState(validateFormValues(formValues))

  useEffect(() => {
    setFormValid(validateFormValues(formValues))
  }, [formValues])

  const handleSave = async () => {
    setLoading(true)
    setErrorMessage('')
    try {
      await scheduleColdStartDate({
        storeIds: formValues.storeId.split(','),
        workflowIds: formValues.workflowId.split(','),
        firstOrderDate: dayjs(formValues.firstOrderDate).format('YYYY-MM-DD'),
      })
      onSuccess()
    } catch (e) {
      setErrorMessage((e as Error).message)
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async () => {
    setLoading(true)
    setErrorMessage('')
    if (onDelete) {
      onDelete()
    }
    try {
      await cancelColdStartDate({
        storeId: activeRow!.storeId,
        workflowId: activeRow!.workflowId,
      })
      onSuccess()
    } catch (e) {
      setErrorMessage((e as Error).message)
    } finally {
      setLoading(false)
    }
  }

  const handleFirstOrderDateChange = useCallback(
    (value: string | null) => {
      setFormValues((draft) => {
        if (value === null) {
          draft.firstOrderDate = ''
          return
        }
        const draftDate = dayjs(value).utc().startOf('day').toISOString()

        draft.firstOrderDate = draftDate
      })
    },
    [setFormValues],
  )

  const isIDChangeDisabled = modalState !== ModalState.add

  return (
    <Dialog disabled={loading} isOpen onClose={onClose}>
      <Header disabled={loading} onClose={onClose}>
        <Title>{`${modalState} Cold Start Date`}</Title>
      </Header>
      <Body>
        <StyledDialogContent>
          <SelectFieldContainer>
            <Tooltip
              title={isIDChangeDisabled ? DISABLED_TOOLTOP : STORE_TOOLTIP}
              placement="top">
              <StyledTextField
                label="Store IDs"
                value={formValues.storeId}
                disabled={isIDChangeDisabled}
                onChange={(e) => {
                  setFormValues((draft) => {
                    draft.storeId = validateNumericInput(e.target.value)
                  })
                }}
              />
            </Tooltip>
            <Tooltip
              title={isIDChangeDisabled ? DISABLED_TOOLTOP : WORKFLOW_TOOLTIP}
              placement="top">
              <StyledTextField
                label="Workflow IDs"
                value={formValues.workflowId}
                disabled={isIDChangeDisabled}
                onChange={(e) => {
                  setFormValues((draft) => {
                    draft.workflowId = validateNumericInput(e.target.value)
                  })
                }}
              />
            </Tooltip>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="First Order Date"
                value={formValues.firstOrderDate}
                minDate={today}
                inputFormat="MMM DD, YYYY"
                onChange={(value) => handleFirstOrderDateChange(value)}
                onClose={() => setDatePickerTouched(true)}
                renderInput={(params) => {
                  return (
                    <StyledTextField
                      {...params}
                      id="date-picker"
                      fullWidth
                      error={datePickerTouched && params.error}
                      label="First Order Date"
                    />
                  )
                }}
              />
            </LocalizationProvider>
          </SelectFieldContainer>
          {errorMessage && (
            <StyledErrorMessage message={errorMessage} severityType="error" />
          )}
        </StyledDialogContent>
      </Body>
      <Footer>
        {onDelete && activeRow && !activeRow.isColdStartComplete && (
          <FooterActions justify="start">
            <Button
              startIcon={<Delete />}
              onClick={handleDelete}
              variant="tertiary">
              Delete
            </Button>
          </FooterActions>
        )}
        <FooterActions>
          <Button disabled={loading} onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button
            disabled={loading || !formValid}
            loading={loading}
            onClick={handleSave}>
            Save
          </Button>
        </FooterActions>
      </Footer>
    </Dialog>
  )
}
