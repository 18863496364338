import { CancelColdStartInput } from '@api/__gen__/gql'
import { gql, useMutation } from '@apollo/client'
import { useCustomers } from 'app/CustomerContext'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

export const CANCEL_COLD_START = gql`
  mutation cancelColdStart($input: CancelColdStartInput!) {
    cancelColdStart(input: $input) {
      success
    }
  }
`

type UserCancelColdStartInput = Omit<CancelColdStartInput, 'customerKey'>

export function useCancelColdStartDate() {
  const { activeCustomerKey } = useCustomers()

  const [cancelColdStart, result] = useMutation(CANCEL_COLD_START, {
    refetchQueries: ['CustomerConfigColdStartDates'],
  })

  return tuple([
    useCallback(
      async (input: UserCancelColdStartInput) => {
        const { errors } = await cancelColdStart({
          variables: {
            input: {
              ...input,
              customerKey: activeCustomerKey,
            },
          },
        })

        if (errors?.length) {
          throw new Error(
            `Cancel cold start dates failed: ${errors[0].message}`,
          )
        }
      },
      [activeCustomerKey, cancelColdStart],
    ),
    result,
  ])
}
