import { GetColdStartDatesQuery } from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import { useCustomers } from 'app/CustomerContext'

export const GET_COLD_START_DATES = gql`
  query GetColdStartDates($customerId: ID!) {
    customerConfig(customerId: $customerId) {
      coldStartDates {
        firstOrderDate
        isColdStartComplete
        storeId
        workflowId
      }
    }
  }
`

function reshapeData(data?: GetColdStartDatesQuery) {
  if (data?.customerConfig?.__typename === 'CustomerConfigQueries') {
    return data.customerConfig?.coldStartDates
  }

  return []
}

export default function useGetColdStartDates() {
  const { activeCustomerId } = useCustomers()

  const { data, loading, refetch } = useQuery<GetColdStartDatesQuery>(
    GET_COLD_START_DATES,
    {
      skip: !activeCustomerId,
      variables: { customerId: activeCustomerId! },
    },
  )

  return {
    data: reshapeData(data),
    loading,
    refetch,
  }
}
