import InternalPageIndex from 'app/packages/internal//InternalPageIndex'
import { RouteConfig } from 'app/packages/internal//routeTypes'
import AdSchedules from 'app/packages/internal/customerConfigs/adSchedules/pages/AdSchedules'
import BusinessLevelDisplayNames from 'app/packages/internal/customerConfigs/businessLevelDisplayNames/pages/BusinessLevelDisplayNames'
import ColdStartDates from 'app/packages/internal/customerConfigs/coldStartDates/pages/ColdStartDates'
import Configurations from 'app/packages/internal/customerConfigs/configurations/pages/Configurations'
import DepartmentBundleManager from 'app/packages/internal/customerConfigs/departmentBundleManager/pages/DepartmentBundleManager'
import OrderAutoUpdate from 'app/packages/internal/customerConfigs/orderAutoUpdate/pages/OrderAutoUpdate'
import UnloadOrderSequence from 'app/packages/internal/customerConfigs/unloadOrderSequence/pages/UnloadOrderSequence'
import WorkflowAvailability from 'app/packages/internal/customerConfigs/workflowAvailability/pages/WorkflowAvailability'
import React from 'react'

const childRoutes: RouteConfig[] = [
  {
    name: 'Ad Schedules',
    path: 'ad-schedules',
    route: 'configs/ad-schedules',
    element: <AdSchedules />,
  },
  {
    name: 'Business Level Display Names',
    path: 'business-level-display-names',
    route: 'configs/business-level-display-names',
    element: <BusinessLevelDisplayNames />,
  },
  {
    name: 'Cold Start Dates',
    path: 'cold-start-dates',
    route: 'configs/cold-start-dates',
    element: <ColdStartDates />,
  },
  {
    name: 'Department Bundles',
    path: 'department-bundles',
    route: 'configs/department-bundles',
    element: <DepartmentBundleManager />,
  },
  {
    name: 'Order Auto Update',
    path: 'order-auto-update',
    route: 'configs/order-auto-update',
    element: <OrderAutoUpdate />,
  },
  {
    name: 'RDB Configurations',
    path: 'rdb-configurations',
    route: 'configs/rdb-configurations',
    element: <Configurations />,
  },
  {
    name: 'Unload Order Sequence',
    path: 'unload-order-sequence',
    route: 'configs/unload-order-sequence',
    element: <UnloadOrderSequence />,
  },
  {
    name: 'Workflow Availability',
    path: 'workflow-availability/*',
    route: 'configs/workflow-availability/*',
    element: <WorkflowAvailability />,
  },
]

const customerConfigRoutes = {
  path: 'configs',
  routes: [
    {
      index: true,
      name: 'Customer Configs',
      route: 'configs',
      element: (
        <InternalPageIndex
          childLinks={childRoutes}
          indexName="Customer Configs"
        />
      ),
    } as RouteConfig,
    ...childRoutes,
  ],
}

export default customerConfigRoutes
