import Accordion from 'app/components/Accordion/Accordion'
import {
  StyledHeader,
  StyledSubheader,
} from 'app/packages/internal/customerConfigs/styles'
import React, { useCallback, useState } from 'react'
import useGetColdStartDates from '../api/queries/useGetColdStartDates'
import ColdStartDatesTable from './ColdStartDatesTable'

export default function ColdStartDates() {
  const [setupDetailsExpanded, setSetupDetailsExpanded] = useState(false)
  const { data, loading, refetch } = useGetColdStartDates()

  const handleRefetch = useCallback(async () => {
    await refetch()
  }, [refetch])

  return (
    <>
      <StyledHeader>Cold Start Dates</StyledHeader>
      <Accordion
        header="Setup instructions and details"
        expanded={setupDetailsExpanded}
        onChangeExpanded={(expanded: boolean) =>
          setSetupDetailsExpanded(expanded)
        }>
        <StyledSubheader>
          This configuration is used to track cold start dates for stores and
          workflows.
          <ul>
            <li>
              <b>Store</b> and <b>Workflow</b> are both required in order to{' '}
              <i>cold start</i> load the necessary data prior to the first
              order.
            </li>
            <li>
              <b>First Order Date</b> corresponds to the date for which the
              first order should be ready, therefore the cold start will run on
              that date.
            </li>
            <li>
              <b>Is Complete?</b> indicates whether the cold start has run or
              not. <i>NO</i> acts as a trigger to run on the given{' '}
              <i>First Order Date</i>
            </li>
          </ul>
        </StyledSubheader>
      </Accordion>
      <ColdStartDatesTable
        rows={data}
        isLoading={loading}
        setupDetailsExpanded={setupDetailsExpanded}
        onRefetch={handleRefetch}
      />
    </>
  )
}
